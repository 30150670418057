import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '@/mui/theme';
import { ServiceMenuItemData } from '@/components/ui/service-nav';
import { buildHeaderMenuIcon } from "@/utils/icons-map";
import { MainMenuItemData } from "@/components/ui/main-menu/";
import HeaderDesktop from "@/components/ui/HeaderDesktop";
import HeaderMobile from "@/components/ui/HeaderMobile";
import { WPLanguage, WPTranslation } from "@/data/wp/operations/queries/fragments/languages";
import { PageType } from "@/utils/router";

interface HeaderProps {
  pageType: PageType
  languages?: WPLanguage[],
  translations?: WPTranslation[],
  mainMenus?: Array<any>,
  serviceMenus?: Array<any>,
  slug?: string
}

const Header: React.FC<HeaderProps> = ({ pageType, languages, translations, mainMenus, serviceMenus }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // SERVICE NAV
  const serviceMenuData: MainMenuItemData[] = [];
  serviceMenus?.map((FLItemCMS: any) => {
    if (FLItemCMS?.node) {
      // prepare the first level item
      const FLItemUI: ServiceMenuItemData = {
        label: FLItemCMS.node.label,
        url: FLItemCMS.node.url,
      };
      // look if there is any second level items
      if (FLItemCMS.node?.childItems && FLItemCMS.node.childItems?.edges) {
        const FLItemChildsUI: ServiceMenuItemData[] = [];
        FLItemCMS.node.childItems.edges.map((SLItemCMS: any) => {
          if (SLItemCMS?.node) {
            // prepare and add the second level item
            const SLItemUI: ServiceMenuItemData = {
              label: SLItemCMS.node.label,
              url: SLItemCMS.node.url,
            };
            FLItemChildsUI.push(SLItemUI);
          }
        });
        if (FLItemChildsUI.length > 0) {
          FLItemUI.items = FLItemChildsUI;
        }
      }
      serviceMenuData.push(FLItemUI);
    }
  });

  // MEGA MENU
  const mainMenuData: MainMenuItemData[] = [];
  mainMenus?.map((FLItemCMS: any) => {
    if (FLItemCMS?.node) {
      // prepare the first level item
      const FLItemUI: MainMenuItemData = {
        label: FLItemCMS.node.label,
        url: FLItemCMS.node.url,
      };
      // look if there is any second level items
      if (FLItemCMS.node?.childItems && FLItemCMS.node.childItems?.edges) {
        const FLItemChildsUI: MainMenuItemData[] = [];
        FLItemCMS.node.childItems.edges.map((SLItemCMS: any) => {
          if (SLItemCMS?.node) {

            // prepare the second level item
            const SLItemUI: MainMenuItemData = {
              label: SLItemCMS.node.label,
              url: SLItemCMS.node.url,
            };
            if (SLItemCMS.node?.fieldsMenu?.viewalllabel) {
              SLItemUI.viewAllLabel = SLItemCMS.node?.fieldsMenu?.viewalllabel;
            }

            // look if there is any second level items
            if (SLItemCMS.node?.childItems && SLItemCMS.node.childItems?.edges) {
              const SLItemChildsUI: MainMenuItemData[] = [];
              SLItemCMS.node.childItems.edges.map((TLItemCMS: any) => {
                if (TLItemCMS?.node) {

                  // prepare the third level item
                  const TLItemUI: MainMenuItemData = {
                    label: TLItemCMS.node.label,
                    url: TLItemCMS.node.url,
                  };

                  if (TLItemCMS.node?.fieldsMenu?.icon) {
                    TLItemUI.icon = buildHeaderMenuIcon('menu-' + TLItemCMS.node.fieldsMenu.icon);
                  }

                  SLItemChildsUI.push(TLItemUI);
                }
              });
              if (SLItemChildsUI.length > 0) {
                SLItemUI.items = SLItemChildsUI;
              }
            }
            FLItemChildsUI.push(SLItemUI);
          }
        });
        if (FLItemChildsUI.length > 0) {
          FLItemUI.items = FLItemChildsUI;
        }
      }
      mainMenuData.push(FLItemUI);
    }
  })

  if (isDesktop) {
    return <HeaderDesktop pageType={pageType} languages={languages} translations={translations} serviceMenu={serviceMenuData} mainMenu={mainMenuData} />
  } else {
    return <HeaderMobile pageType={pageType} languages={languages} translations={translations} serviceMenu={serviceMenuData} mainMenu={mainMenuData} />
  }
};

export default Header;
