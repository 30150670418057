import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import LanguageSwitcher from './LanguageSwitcher';
import ServiceNav from './service-nav/mobile/ServiceNav';
import MobileMenu from './main-menu/mobile';
import { BurgerIcon, CloseIcon } from '../icons';
import IconButton from '@mui/material/IconButton';
import Logo from '@/components/ui/Logo';
import HeaderSearch from './header-search/HeaderSearch';
import { WPLanguage, WPTranslation } from '@/data/wp/operations/queries/fragments/languages';
import { PageType } from '@/utils/router';
import { useRouter } from 'next/router';

const StyledHeaderBox = styled(Box)({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  background: '#fff',
  borderBottom: 'solid 1px #979797',
});

const StyledDrawer = styled(Drawer)({
  '>.MuiPaper-root': {
    width: '100%',
    background: '#ededed',
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '65px',
  background: '#fff',
  borderBottom: 'solid 1px #979797',
}));

interface HeaderMobileProps {
  pageType: PageType
  languages?: WPLanguage[]
  translations?: WPTranslation[]
  serviceMenu?: any
  mainMenu?: any
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ pageType, languages, translations, serviceMenu, mainMenu }) => {
  const { t } = useTranslation('common');
  const { asPath } = useRouter();
  const [drawerState, setDrawerState] = React.useState(false);

  useEffect(() => {
    toggleDrawer(false);
  }, [asPath]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if ( event.type === 'keydown' && (
      (event as React.KeyboardEvent).key === 'Tab' ||
      (event as React.KeyboardEvent).key === 'Shift'
    )) {
      return;
    }
    setDrawerState(open);
  };

	return (
    <StyledHeaderBox component="header">

      <Container>
        <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ height: '65px' }}>
          <Logo />
          <Box sx={{display: 'flex'}}>
            <HeaderSearch />
            <IconButton onClick={toggleDrawer(true)} sx={{ width: 50, height: 50 }} aria-label={t('header-mobile-open-menu',{},{default:'Open Main Menu'})}>
              <BurgerIcon />
            </IconButton>
          </Box>
        </Stack>
      </Container>

      <StyledDrawer
        open={drawerState}
        onClose={toggleDrawer(false)}
        variant="persistent"
      >
        <DrawerHeader>
          <Container>
            <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ height: '65px' }}>
              <Logo />
              <IconButton onClick={toggleDrawer(false)} aria-label={t('header-mobile-close-menu',{},{default:'Close Main Menu'})}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Container>
        </DrawerHeader>
        <Stack flexDirection='column' justifyContent='space-between'>
          <MobileMenu data={mainMenu} />
          <ServiceNav data={serviceMenu} />
          <LanguageSwitcher pageType={pageType} languages={languages} translations={translations} />
        </Stack>
      </StyledDrawer>

    </StyledHeaderBox>
	);
};

export default HeaderMobile;
