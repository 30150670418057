import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import OmniLink from '@/components/ui/OmniLink';
import { ServiceMenuItemData } from '@/components/ui/service-nav';

const StyledButton = styled((props: ButtonProps) => (
  <Button disableTouchRipple={true} {...props}></Button>
))(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 0,
  minHeight: '45px',
  margin: 0,
  padding: `${theme.spacing(2)}`,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: 0,
  fontWeight: 'normal',
  color: '#1c1c1c',
  borderTop: `solid 1px ${theme.palette.divider}`,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

interface MobileMenuProps {
  data: ServiceMenuItemData[]
}

const SecondLevel: React.FC<MobileMenuProps> = ({ data }) => {
	return (<div>
    {data.map((item, index) => {
      if (item.url) {
        return <StyledButton key={index} LinkComponent={OmniLink} href={item.url}>{item.label}</StyledButton>
      } else {
        return <StyledButton key={index}>{item.label}</StyledButton>
      }
    })}
  </div>);
};

export default SecondLevel;
