import React from 'react';
import isEmpty from 'lodash/isEmpty';
import useTranslation from 'next-translate/useTranslation'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CaretDownIcon } from '../icons';
import Divider from '@mui/material/Divider';
import { WPLanguage, WPTranslation } from '@/data/wp/operations/queries/fragments/languages';
import { useRouter } from 'next/router';
import { buildPageUrl, PageType } from '@/utils/router';

const DEFAULT_LANG = 'it';

const StyledButton = styled(Button)(({theme}) => ({
  minWidth: 0,
  borderRadius: 0,
  marginLeft: '-8px',
  paddingLeft: '8px',
  paddingRight: '8px',
  color: '#e0e0e0',
  fontSize: '13px',
  lineHeight: '18px',
  fontWeight: 'normal',
  letterSpacing: '-0.1px',
  '.MuiButton-endIcon': {
    marginLeft: '4px',
    transition: 'transform 150ms ease-in',
  },
  '&.active': {
    backgroundColor: '#3d3d3d',
    '.MuiButton-endIcon': {
      transform: 'rotate(180deg)'
    }
  },
  [theme.breakpoints.down('lg')]: {
    color: '#000',
    padding: '16px',
    width: '100%',
    justifyContent: 'flex-start',
    margin: '20px 0',
    borderTop: 'solid 1px #ccc',
    borderBottom: 'solid 1px #ccc',
    fontSize: '15px',
    backgroundColor: '#fff',
    '&.active': {
      backgroundColor: '#fff',
    }
  },
}));

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)({
  '.MuiList-root': {
    padding: 0,
    minWidth: '150px',
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: '#606060',
    color: '#ffffff',
  },
});

const StyledMenuItem = styled(MenuItem)({
  padding: '11px 15px',
  '&.MuiMenuItem-root': {
    padding: 0,
    '&.Mui-selected': {
      backgroundColor: '#3d3d3d',
    },
    '& + .MuiDivider-root': {
      marginTop: 0,
      marginBottom: 0,
    }
  }
});

const StyledMenuItemButton = styled(Button)({
  flexGrow: 1,
  padding: '11px 15px',
  justifyContent: 'flex-start',
  color: '#ffffff',
  fontWeight: 'normal',
  fontSize: '13px',
  lineHeight: '17px',
  letterSpacing: '0px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
});

const StyledDivider = styled(Divider)({
  borderColor: '#6e6e6e',
});

interface LanguageSwitcherItem {
  isSelected: boolean,
  langId: string,
  langLabel: string,
  langSlug: string,
  targetUrl: string,
}

interface LanguageSwitcherProps {
  pageType: PageType
  languages?: WPLanguage[]
  translations?: WPTranslation[]
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ languages, translations }) => {
  // Get the info we need from the Next.js router
  const router = useRouter();
  const { locale = DEFAULT_LANG } = router;
  // const { pathname, asPath, query, locale = DEFAULT_LANG } = router;

  // Label translation function
  const { t } = useTranslation('common');

  const [popupAnchorEl, setPopupAnchorEl] = React.useState<null | HTMLElement>(null);
  const isPopupOpen = Boolean(popupAnchorEl);

  const menuItems: LanguageSwitcherItem[] = [];
  languages?.map((language) => {
    const translation = translations?.find((translation) => translation.language.id === language.id);

    // If there is a translation for the current page use that url,
    // otherwise fallbacks on the homepage url.
    //
    // IMPORTANT: the project assumption here is that the locales
    // set inside nextI18nConfig (listed by the switch and used in the urls)
    // are just languages (eg: 'en') and DOES NOT INCLUDE THE COUNTRY (eg: 'en-US')
    //
    let targetUrl = translation?.uri || buildPageUrl(PageType.HOME, {}, language.slug);
    if (translation?.slug === 'homepage') {
      targetUrl = buildPageUrl(PageType.HOME, {}, language.slug);
    }

    const item = {
      isSelected: language.slug === locale,
      langId: language.id,
      langLabel: language.name,
      langSlug: language.slug,
      targetUrl: targetUrl,
    };
    menuItems.push(item);
  });

  const openPopup = (anchorEl: HTMLElement) => {
    setPopupAnchorEl(anchorEl);
  };

  const closePopup = () => {
    setPopupAnchorEl(null);
  };

  const handlePopupOpenerClick = (event: React.MouseEvent<HTMLElement>) => {
    openPopup(event.currentTarget);
  };

  const handleItemClick = (clickedItem: LanguageSwitcherItem) => {
    // Assumption: consider NEXT_LOCALE as a Strictly necessary cookie
    // (otherwise we would need to get the user cookie consent, from cookie-scripts.com)
    const hasCookieConsent = true;

    // Set the locale preference cookie, so that next time the user accesse the website without specifying the locale
    // he will be redirected to this locale, no matter what are his accepted-language headers.
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=${clickedItem.langSlug}; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }

    router.push({ pathname: clickedItem.targetUrl }, undefined, { locale: false });
    // router.push({ pathname, query }, asPath, { locale: clickedItem.langSlug });

    closePopup();
  }

  if (isEmpty(languages)) {
    return null;
  }

  return (
    <div>
      <StyledButton
        id="language-switcher-button"
        aria-controls="language-switcher-menu"
        aria-haspopup="true"
        aria-expanded={isPopupOpen ? 'true' : undefined}
        disableElevation
        onClick={handlePopupOpenerClick}
        endIcon={<CaretDownIcon width="10" />}
        className={isPopupOpen ? 'active': ''}
      >
        {t('language-switcher-languages', {}, {default: 'Languages'})}
      </StyledButton>
      <StyledMenu
        id="language-switcher-menu"
        MenuListProps={{ 'aria-labelledby': 'language-switcher-button', }}
        anchorEl={popupAnchorEl}
        open={isPopupOpen}
        onClose={closePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems!.map((item) => {
          return (<div key={item.langId}>
            <StyledMenuItem selected={item.isSelected}>
              <StyledMenuItemButton variant="text" onClick={() => handleItemClick(item)}>
                {item.langLabel}
              </StyledMenuItemButton>
            </StyledMenuItem>
            <StyledDivider />
          </div>)
        })}
      </StyledMenu>
    </div>
  );
}

export default LanguageSwitcher;
