import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { ArrowSquareIcon } from '@/components/icons';
import OmniLink from '@/components/ui/OmniLink';
import { ServiceMenuItemData } from '@/components/ui/service-nav';
import { SecondLevelItem } from './SecondLevelItem';

const StyledButton = styled(Button)({
  minWidth: 0,
  padding: '11px 14px 12px',
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '-0.1px',
  color: '#ffffff',
  borderRadius: 0,
  '.MuiButton-endIcon': {
    marginLeft: '4px',
    transition: 'transform 150ms ease-in',
  },
  '&.active': {
    backgroundColor: '#3d3d3d',
    '.MuiButton-endIcon': {
      transform: 'rotate(90deg)'
    }
  },
});

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)({
  '.MuiList-root': {
    padding: 0,
    minWidth: '150px',
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: '#606060',
    color: '#ffffff',
  },
});

const StyledDivider = styled(Divider)({
  borderColor: '#6e6e6e',
});

interface FirstLevelItemProps {
  data: ServiceMenuItemData
}

/**
 * First-level items of the ServiceNav.
 */
const FirstLevelItem: React.FC<FirstLevelItemProps> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  if (data.items) {

    // CASE | with subitems: first-level button + menu with subitems
    return (<>
      <StyledButton onClick={handleClick} endIcon={<ArrowSquareIcon fill={'#fff'} />} className={open ? 'active': ''}>
        {data.label}
      </StyledButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {data.items?.map((item: ServiceMenuItemData, index: number) => {
          return (<div key={index}>
            {index === 0 ? null: <StyledDivider />}
            <SecondLevelItem
              label={item.label}
              href={item.url}
              onClick={() => {
                handleClose();
              }}
            />
          </div>)
        })}
      </StyledMenu>
    </>);

  } else if (data.url) {
    // CASE | button with direct link
    return (<StyledButton LinkComponent={OmniLink} href={data.url}>
      {data.label}
    </StyledButton>)

  } else {
    // CASE | fallback: button without link
    return <StyledButton>
      {data.label}
    </StyledButton>
  }
};

export default FirstLevelItem;
