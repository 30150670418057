import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OmniLink from "@/components/ui/OmniLink";

const StyledMenuItem = styled(MenuItem)({
  padding: 23,
  display: "flex",
  justifyContent: "space-between",
  'svg': {
    opacity: 0,
    transition: 'opacity 150ms ease-in',
  },
  '&.active': {
    background: '#ededed',
    '.MuiTypography-root': {
      fontWeight: 'bold',
    },
    'svg': {
      opacity: 1,
    }
  },
  '& + .MuiMenuItem-root': {
    borderTop: 'solid 1px #979797',
  }
});

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: 0,
});

const StyledBox = styled(Box)({
  display: 'flex',
});

/**
 * Second and third level item of the MegaMenu component.
 * It handle left and right icons if needed.
 */
export const SecondLevelMenuItem: React.FC<any> = ({
  href,
  leftIcon,
  rightIcon,
  onClick,
  label,
  MenuItemProps,
  ref,
  active,
}) => {
  return (
    <StyledMenuItem ref={ref} {...(href ? { component: OmniLink, href: href } : {})} onClick={onClick} {...MenuItemProps} className={active ? 'active': ''}>
      <StyledBox>
        {leftIcon}
        <StyledTypography>{label}</StyledTypography>
      </StyledBox>
      {rightIcon}
    </StyledMenuItem>
  );
};
