import React from 'react';
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image';
import OmniLink from './OmniLink';
import logoDisano from '@/public/logo-disano.png';

const Logo: React.FC = () => {
  const { t } = useTranslation('common');
	return (
    <OmniLink href="/" sx={{ my: 'auto', fontSize: 0, lineHeight: 0, alignSelf: 'center' }}>
      <Image priority src={logoDisano} alt={t('logo-disano-alt',{},{default:'Disano'})} width="165" height="51" />
    </OmniLink>
	);
};

export default Logo;
