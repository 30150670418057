import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FirstLevelItem from './FirstLevelItem';
import { ServiceMenuItemData } from '@/components/ui/service-nav';

const StyledDivider = styled(Divider)({
  borderColor: '#6e6e6e',
});

interface ServiceNavProps {
  data?: ServiceMenuItemData[]
}

const ServiceNav: React.FC<ServiceNavProps> = ({ data }) => {
	if (isEmpty(data)) {
		return null;
	}

  return (<Stack
    direction="row"
    divider={<StyledDivider orientation="vertical" flexItem />}
    sx={{marginLeft: 'auto'}}
  >
    {data!.map((item, index) => <FirstLevelItem key={index} data={item} />)}
  </Stack>);
}

export default ServiceNav;
