import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ArrowSquareIcon from '@/components/icons/ArrowSquareIcon';
import Button, { ButtonProps } from '@mui/material/Button';
import OmniLink from '@/components/ui/OmniLink';
import { ServiceMenuItemData } from '@/components/ui/service-nav';
import SecondLevel from './SecondLevel';

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderBottom: `solid 1px ${theme.palette.divider}`,
}));

const StyledButton = styled((props: ButtonProps) => (
  <Button disableTouchRipple={true} {...props}></Button>
))(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 0,
  minHeight: '56px',
  margin: 0,
  padding: theme.spacing(2),
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: 0,
  fontWeight: 'normal',
  color: '#1c1c1c',
  borderTop: `solid 1px ${theme.palette.divider}`,
  backgroundColor: '#fff',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: '#fff',
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowSquareIcon width="17px" height="17px" />} {...props} />
))({
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: 0,
  color: '#1c1c1c',
  minHeight: '56px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  background: '#f7f7f7',
});


interface ServiceNavProps {
  data: ServiceMenuItemData[]
}

const ServiceNav: React.FC<ServiceNavProps> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

	if (isEmpty(data)) {
		return null;
	}

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (<StyledBox>
    {data.map((item, index) => {
      if (item.items) {
       return <Accordion key={index} expanded={expanded === `mobileMenuAccordion${index}`} onChange={handleChange(`mobileMenuAccordion${index}`)}>
          <AccordionSummary aria-controls={`mobileMenuAccordion${index}-content`} id={`mobileMenuAccordion${index}-header`}>
            {item.label}
          </AccordionSummary>
          <AccordionDetails>
            <SecondLevel data={item.items} />
          </AccordionDetails>
        </Accordion>
      } else if (item.url) {
        return <StyledButton key={index} LinkComponent={OmniLink} href={item.url}>{item.label}</StyledButton>
      } else {
        return <StyledButton key={index}>{item.label}</StyledButton>
      }
    })}
  </StyledBox>);
}

export default ServiceNav;
