import React from 'react';
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import OmniLink from '@/components/ui/OmniLink';
import {getIconComponentByName} from '@/utils/icons-map';

const StyledItem = styled(Box)({
  padding: '5px'
});

interface SocialLinksProps {
  socialLinks: any
}

const SocialLinks: React.FC<SocialLinksProps> = ({ socialLinks }) => {
	return (!isEmpty(socialLinks) && isArray(socialLinks) ? (
    <Stack direction="row" spacing={0} justifyContent="center" sx={{ my: '20px' }}>
      { socialLinks?.map((socialLink: any, index) => (
        <StyledItem key={index}>
          <OmniLink href={socialLink?.node?.url}>
            { getIconComponentByName( socialLink?.node?.fieldsSocialMenu?.icon ) }
          </OmniLink>
        </StyledItem>
      ) ) }
    </Stack>
  ) : null);
}

export default SocialLinks;
