import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OmniLink from "@/components/ui/OmniLink";

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: 'center',
  justifyContent: "space-between",
  breakInside: 'avoid',
  whiteSpace: 'pre-wrap',
  padding: '0 10px 0 0',
  margin: '5px 0',
});

const StyledTypography = styled(Typography)({
  paddingLeft: '20px',
  textAlign: 'left',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0px',
});

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minHeight: '58px',
});

/**
 * Second and third level item of the MegaMenu component.
 * It handle left and right icons if needed.
 */
export const ThirdLevelMenuItem: React.FC<any> = ({
  href,
  leftIcon,
  rightIcon,
  onClick,
  label,
  MenuItemProps,
  className,
  ref,
}) => {
  return (
    <StyledMenuItem ref={ref} {...(href ? { component: OmniLink, href: href, disableClientSideNavigation: true } : {})} className={className} onClick={onClick} {...MenuItemProps}>
      <StyledBox>
        {leftIcon}
        <StyledTypography>{label}</StyledTypography>
      </StyledBox>
      {rightIcon}
    </StyledMenuItem>
  );
};
