import * as React from 'react';
import useTranslation from 'next-translate/useTranslation'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { SearchIcon } from '@/components/icons';
import Button from '@mui/material/Button';
import SearchModal from '@/components/fasce/SearchModal';
import { styled } from '@mui/material';
import { useRouter } from 'next/router';

const FakeButton = styled(Button)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '&:hover': {
    'background': 'rgba(255, 255, 255, 0.4)'
  }
})

interface HeaderSearchProps {
  desktopMode?: boolean
}

const HeaderSearch: React.FC<HeaderSearchProps> = ({ desktopMode = false }) => {
  const { t } = useTranslation('common');
  const { asPath } = useRouter();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(false);
  }, [asPath]);

  const handleOpen = () => {
    document.querySelector('main')!.classList.add('modal-open');
    setIsOpen(true);
  }

  const handleClose = () => {
    document.querySelector('main')!.classList.remove('modal-open');
    setIsOpen(false);
  }

  return (<>
    <Paper
      elevation={0}
      sx={desktopMode ? { position: 'relative', m: '13px 0 13px 25px', p: '0 10px 0 0', display: 'flex', alignItems: 'center', width: 300, height: 58, backgroundColor: '#f1f1f1', borderRadius: '6px' }: {}}
    >
      {desktopMode &&
        <InputBase
          sx={{ ml: 3, flex: 1 }}
          placeholder={t('header-search-placeholder', {}, {default: 'Search'})}
          inputProps={{ 'aria-label': t('header-search-input-label', {}, {default: 'Search'}) }}
        />
      }
      <IconButton onClick={handleOpen} sx={{ width: 50, height: 50 }} aria-label={t('header-search-open', {}, {default: 'Open Search'})}>
        <SearchIcon />
      </IconButton>
      {desktopMode &&
        <FakeButton onClick={handleOpen} aria-label={t('header-search-placeholder', {}, {default: 'Search'})}></FakeButton>
      }
    </Paper>

    <SearchModal isOpen={isOpen} onClose={handleClose} />
  </>);
}

export default HeaderSearch;
