import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Logo from '@/components/ui/Logo';
import LanguageSwitcher from './LanguageSwitcher';
import ServiceNav from './service-nav/desktop/ServiceNav';
import MegaMenu from './main-menu/desktop/MegaMenu';
import HeaderSearch from './header-search/HeaderSearch';
import { WPLanguage, WPTranslation } from '@/data/wp/operations/queries/fragments/languages';
import { PageType } from '@/utils/router';

const StyledHeaderBox = styled(Box)({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
});

interface HeaderDesktopProps {
  pageType: PageType
  languages?: WPLanguage[]
  translations?: WPTranslation[]
  serviceMenu?: any
  mainMenu?: any
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({ pageType, languages, translations, serviceMenu, mainMenu }) => {
	return (
    <StyledHeaderBox component="header">
      <Box sx={{backgroundColor: '#606060'}}>
        <Container fixed>
          <Stack flexDirection='row' justifyContent='space-between'>
            <LanguageSwitcher pageType={pageType} languages={languages} translations={translations} />
            <ServiceNav data={serviceMenu} />
          </Stack>
        </Container>
      </Box>
      <Box sx={{backgroundColor: '#ffffff'}}>
        <Container fixed>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Logo />
            <MegaMenu data={mainMenu} />
            <HeaderSearch desktopMode={true} />
          </Stack>
        </Container>
      </Box>
    </StyledHeaderBox>
	);
};

export default HeaderDesktop;
