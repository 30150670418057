import React from 'react';
import { NextSeo } from "next-seo";
import { getOrigin, useCurrentBrowserPath } from '@/utils/router';
import { WPLanguage, WPTranslation } from '@/data/wp/operations/queries/fragments/languages';
import { useCurrentLocaleLang } from '@/utils/localization';
import { WPPostTypeSeo } from '@/data/wp/operations/queries/fragments/seo';

interface SeoProps {
  seo: WPPostTypeSeo,
  language: WPLanguage,
  translations?: WPTranslation[]
}

/**
 * Custom SEO component
 *
 * Used to seo meta tags for each page
 *
 * @param {Object} seo Seo.
 * @see https://www.npmjs.com/package/next-seo
 *
 * @returns {JSX.Element}
 *
 */
const Seo: React.FC<SeoProps> = ({ seo, language, translations }) => {
  const browserPath = useCurrentBrowserPath();
  const currentLocaleLang = useCurrentLocaleLang();

  const {
    title,
    // canonical,
    metaDesc,
    metaRobotsNoindex,
    metaRobotsNofollow,
    opengraphDescription,
    opengraphTitle,
    opengraphImage,
    opengraphSiteName,
  } = seo;

  const opengraphUrl = getOrigin() + browserPath;

  const opengraphImages = opengraphImage?.mediaItemUrl ? [{
    url: opengraphImage?.mediaItemUrl,
    width: opengraphImage?.mediaDetails?.width, // 1280,
    height: opengraphImage?.mediaDetails?.height, // 720,
  }] : [];

  const languageAlternates = translations?.map((translation) => ({
    hrefLang: translation?.language.slug,
    href: getOrigin() + translation?.uri,
  }));

  return (
    <NextSeo
      title={title}
      description={opengraphDescription || metaDesc}
      // canonical={canonical || opengraphUrl}
      noindex={metaRobotsNoindex === 'noindex'}
      nofollow={metaRobotsNofollow === 'nofollow'}
      languageAlternates={languageAlternates}
      openGraph={{
        type: "website",
        locale: language ? language?.locale: currentLocaleLang,
        url: opengraphUrl,
        title: opengraphTitle,
        description: opengraphDescription,
        images: opengraphImages,
        site_name: opengraphSiteName,
      }}
      // twitter={{
      //   handle: "@twitter-username-here",
      //   site: "@twitter-username-here",
      //   cardType: "summary_large_image",
      // }}
    />
  );
};

export default Seo;
