import React from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import useTranslation from 'next-translate/useTranslation'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import theme from '@/mui/theme';
import Logo from '@/components/ui/Logo';
import { FooterMenuItemData } from '@/components/ui/footer-menu';
import { default as DesktopFooterMenu } from '@/components/ui/footer-menu/desktop/FooterMenu';
import { default as MobileFooterMenu } from '@/components/ui/footer-menu/mobile/FooterMenu';
import SocialLinks from '@/components/ui/SocialLinks';

const StyledFooterBox = styled(Box)(({theme}) => ({
  paddingTop: '20px',
  backgroundColor: '#F7F7F7',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '45px',
  },
}));

const StyledCopyBox = styled(Box)(({ theme }) => ({
  padding: '20px 30px 30px',
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '-0.1px',
  textAlign: 'center',
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    marginTop: '60px',
    borderTop: 'solid 1px #E4E4E4',
  }
}));

interface FooterProps {
  footerMenus?: Array<any>,
  socialLinks?: Array<any>,
}

const Footer: React.FC<FooterProps> = ({footerMenus, socialLinks}) => {
  const { t } = useTranslation('common');
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const footerMenuData: FooterMenuItemData[] = [];
  footerMenus?.map((FLItemCMS: any) => {
    if (FLItemCMS?.node) {
      // prepare the first level item
      const FLItemUI: FooterMenuItemData = {
        label: FLItemCMS.node.label,
        url: FLItemCMS.node.url,
      };
      // look if there is any second level items
      if (FLItemCMS.node?.childItems && FLItemCMS.node.childItems?.edges) {
        const FLItemChildsUI: FooterMenuItemData[] = [];
        FLItemCMS.node.childItems.edges.map((SLItemCMS: any) => {
          if (SLItemCMS?.node) {
            // prepare and add the second level item
            const SLItemUI: FooterMenuItemData = {
              label: SLItemCMS.node.label,
              url: SLItemCMS.node.url,
            };
            FLItemChildsUI.push(SLItemUI);
          }
        });
        if (FLItemChildsUI.length > 0) {
          FLItemUI.items = FLItemChildsUI;
        }
      }
      footerMenuData.push(FLItemUI);
    }
  });

  const year = new Date().getUTCFullYear().toString();
  let footerCopyright = t('footer-copyright', {}, {default: '© {year} Disano Illuminazione S.p.A. - P.IVA 06191460150'});
  footerCopyright = footerCopyright.replace('{year}', year);

	return (
    <StyledFooterBox component="footer">
			<Container fixed>
				<Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{marginRight: 'auto'}}>
            <Stack direction="column">
              <Logo />
              <SocialLinks socialLinks={socialLinks} />
            </Stack>
          </Grid>

          { isDesktop && !isEmpty(footerMenus) && isArray(footerMenus) ? (
            <DesktopFooterMenu data={footerMenuData} />
          ) : null }
				</Grid>
      </Container>

      {!isDesktop && !isEmpty(footerMenuData) &&
        <MobileFooterMenu data={footerMenuData} />
      }

      <Container fixed>
        <StyledCopyBox>
          {footerCopyright}
        </StyledCopyBox>
			</Container>
    </StyledFooterBox>
	);
};

export default Footer;
