import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowSquareIcon from '@/components/icons/ArrowSquareIcon';
import { MainMenuItemData } from '@/components/ui/main-menu';
import Button, { ButtonProps } from '@mui/material/Button';
import OmniLink from '@/components/ui/OmniLink';
import { ThirdLevelItem } from './ThirdLeveltem';

const StyledButton = styled((props: ButtonProps) => (
  <Button disableTouchRipple={true} {...props}></Button>
))(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 0,
  minHeight: '51px',
  margin: 0,
  padding: `${theme.spacing(2)}`,
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: 0,
  fontWeight: 'normal',
  color: '#1c1c1c',
  borderTop: `solid 1px ${theme.palette.divider}`,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  background: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowSquareIcon width="17px" height="17px" />} {...props} />
))({
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: 0,
  color: '#1c1c1c',
  minHeight: '56px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface MobileMenuProps {
  data: MainMenuItemData[]
}

/**
 * Mobile menu, inside a Drawer, with accordions.
 */
const SecondLevel: React.FC<MobileMenuProps> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

	return (<div>
    {data.map((item, index) => {
      if (item.items) {
       return <Accordion key={index} expanded={expanded === `mobileMenuAccordion${index}`} onChange={handleChange(`mobileMenuAccordion${index}`)}>
          <AccordionSummary aria-controls={`mobileMenuAccordion${index}-content`} id={`mobileMenuAccordion${index}-header`}>
            {item.label}
          </AccordionSummary>
          <AccordionDetails>
            {item.items.map((subItem: MainMenuItemData, subIndex: number) => (
              <ThirdLevelItem
                key={subIndex}
                href={subItem.url}
                leftIcon={subItem.icon}
                label={subItem.label}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      } else if (item.url) {
        return <StyledButton key={index} LinkComponent={OmniLink} href={item.url}>{item.label}</StyledButton>
      } else {
        return <StyledButton key={index}>{item.label}</StyledButton>
      }
    })}
  </div>);
};

export default SecondLevel;
