import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import OmniLink from "@/components/ui/OmniLink";
import Typography from "@mui/material/Typography";

const StyledMenuItem = styled(MenuItem)({
  padding: '24px 15px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&.MuiMenuItem-root + .MuiDivider-root': {
    marginTop: 0,
    marginBottom: 0,
  }
});

const StyledTypography = styled(Typography)({
  color: '#ffffff',
  fontSize: '13px',
  lineHeight: '17px',
  letterSpacing: '0px',
  textDecoration: 'none',
});

/**
 * Second level item of the ServiceNav component.
 */
export const SecondLevelItem: React.FC<any> = ({
  label,
  href,
  onClick,
  MenuItemProps,
  className,
  ref,
}) => {
  return (
    <StyledMenuItem ref={ref} {...(href ? { component: OmniLink, href: href } : {})} className={className} onClick={onClick} {...MenuItemProps} >
      <StyledTypography>
        {label}
      </StyledTypography>
    </StyledMenuItem>
  );
};
