import Stack from '@mui/material/Stack';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { MainMenuItemData } from '@/components/ui/main-menu';
import FirstLevelItem from './FirstLevelItem';

interface MegaMenuProps {
  data?: MainMenuItemData[]
}

/**
 * MegaMenu component.
 * An desktop the first-level items will be horizontally aligned, the second level elements will be a vertical menu
 */
const MegaMenu: React.FC<MegaMenuProps> = ({ data }) => {
  if (isEmpty(data)) {
		return null;
	}

	return (<Stack direction="row">
    {data!.map((item, index) => (
      <FirstLevelItem key={index} data={item}></FirstLevelItem>
    ))}
  </Stack>);
};

export default MegaMenu;
