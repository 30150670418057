import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { ArrowSquareIcon } from '@/components/icons';
import OmniLink from '@/components/ui/OmniLink';
import { MainMenuItemData } from '@/components/ui/main-menu';
import { SecondLevelMenuItem } from './SecondLevelMenuItem';
import { NestedMenuItem } from './NestedMenuItem';
import { ThirdLevelMenuItem } from './ThirdLevelMenuItem';

const StyledButton = styled((props: ButtonProps) => <Button disableTouchRipple={true} {...props}></Button>)({
  minWidth: 0,
  padding: '0 8px 0 0',
  marginLeft: '12px',
  marginRight: '4px',
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: 0,
  color: '#1c1c1c',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '.MuiButton-endIcon': {
    marginLeft: '6px',
    transition: 'transform 150ms ease-in',
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '8px',
    backgroundColor: '#f7931e',
    opacity: 0,
    transition: 'opacity 150ms ease-in',
  },
  '&.active': {
    position: 'relative',
    '&::after': {
      opacity: 1,
    },
    '.MuiButton-endIcon': {
      transform: 'rotate(90deg)'
    }
  },
});

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)({
  '.MuiList-root': {
    padding: 0,
    minWidth: '165px',
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: '#ffffff',
    color: '#000000',
  },
});

interface FirstLevelItemProps {
  data: MainMenuItemData
}

/**
 * First-level items of the MegaMenu.
 */
const FirstLevelItem: React.FC<FirstLevelItemProps> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  if (data.items) {

    // CASE | with subitems: first-level button + menu with subitems
    return (<>
      <StyledButton onClick={handleClick} endIcon={<ArrowSquareIcon />} className={isOpen ? 'active': ''}>
        {data.label}
      </StyledButton>
      <StyledMenu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {data.items?.map((item: MainMenuItemData, index: number) => {

          if (item.items) {
            // go deeper to the third level
            return (
              <NestedMenuItem
                key={index}
                icon={item.icon}
                label={item.label}
                parentMenuOpen={isOpen}
                viewAllLabel={item.viewAllLabel}
                viewAllUrl={item.url}
              >
                {item.items.map((subItem: MainMenuItemData, subIndex: number) => (
                  <ThirdLevelMenuItem
                    key={subIndex}
                    href={subItem.url}
                    leftIcon={subItem.icon}
                    label={subItem.label}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                ))}
              </NestedMenuItem>
            );

          } else {
            // No children elements, return MenuItem
            return (
              <SecondLevelMenuItem
                key={index}
                href={item.url}
                leftIcon={item.icon}
                label={item.label}
                onClick={() => {
                  handleClose();
                }}
              />
            );
          }
        })}
      </StyledMenu>
    </>);

  } else if (data.url) {
    // CASE | button with direct link
    return (<StyledButton LinkComponent={OmniLink} href={data.url}>
      {data.label}
    </StyledButton>)

  } else {
    // CASE | fallback: button without link
    return <StyledButton>
      {data.label}
    </StyledButton>
  }
};

export default FirstLevelItem;
