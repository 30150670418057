import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OmniLink from '@/components/ui/OmniLink';
import { FooterMenuItemData } from '..';

const StyledListSubheader = styled(ListSubheader)({
  padding: '5px 0',
  color: '#1c1c1c',
  fontWeight: 'bold',
  fontSize: '15px',
  lineHeight: '22px',
  letterSpacing: '-0.1px',
  background: 'transparent',
});

const StyledListItemButton = styled(ListItemButton)({
  display: 'block',
  padding: '5px 0',
});

const StyledListItemText = styled(ListItemText)({
  margin: 0,
  '.MuiListItemText-primary': {
    color: '#545454',
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '-0.2px',
  },
});

interface FooterMenuProps {
  data: FooterMenuItemData[]
}

const FooterMenu: React.FC<FooterMenuProps> = ({ data }) => {
	if (isEmpty(data)) {
		return null;
	}

  return (<>
    {data.map((FLItem, FLIndex) => {
      const twoCols = FLItem?.items?.length ? FLItem?.items?.length > 4: false;
      return (<Grid key={FLIndex} item xs={12} md={twoCols ? 4: 2 }>
        <List
          sx={{ width: '100%' }}
          component="nav"
          aria-labelledby={`mobFooterSubheader${FLIndex}`}
          subheader={
            /* @ts-ignore */
            <StyledListSubheader id={`mobFooterSubheader${FLIndex}`} component="div">
              {FLItem.label}
            </StyledListSubheader>
          }
        >
          <Box sx={twoCols ? {columns: 2, columnGap: "20px"}: {}}>
            {FLItem.items?.map((SLItem: FooterMenuItemData, SLIndex: number) => {
              return (<StyledListItemButton key={SLIndex} {...(SLItem.url ? { component: OmniLink, href: SLItem.url } : {})}>
                <StyledListItemText primary={SLItem.label} />
              </StyledListItemButton>)
            })}
          </Box>
        </List>
      </Grid>)
    })}
  </>);
}

export default FooterMenu;
