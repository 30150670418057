import React from 'react';
import Head from "next/head";
import { useRouter } from 'next/router';
import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocalizedTheme } from '@/mui/theme';
import Header from "./header";
import Footer from "./footer";
import Seo from '@/components/seo';
import { WPLanguage, WPTranslation } from '@/data/wp/operations/queries/fragments/languages';
import { WPPostTypeSeo } from '@/data/wp/operations/queries/fragments/seo';
// import { sanitize } from '@/utils/miscellaneous';
import { PageType } from '@/utils/router';

const StyledBox = styled(Box)({
  '&.modal-open': {
    filter: 'blur(10px)',
  }
});

interface SiteLayoutProps {
  pageType: PageType
  data: any,
  translations?: WPTranslation[],
  seo?: WPPostTypeSeo,
  language?: WPLanguage,
  children: any,
}

const SiteLayout: React.FC<SiteLayoutProps> = ({ pageType, data = {}, translations, seo, language, children = {} }) => {
  const { languages, mainMenus, serviceMenus, footerMenus, socialLinks } = data?._structuralData || {};

  const router = useRouter();
  const theme = useLocalizedTheme(router.locale);

  return (
    <Box>
      {(seo && language) &&
        <Seo seo={seo} language={language} translations={translations} />
      }
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="facebook-domain-verification" content="qgd0zdb5lqvplawqu7vwxd0fk5joj3" />

        {/*
          Commentato in quanto lo schema JSON-LD dal plugin "Add WPGraphQL SEO" contiene troppe informazioni,
          e con le url sbagliate che puntano al wordpress invece che al sito frontend.

          Quando sarà il momento di implementare i JSON-LD usare la funzionalità del package next-seo nel componente Seo.
          vedi https://www.npmjs.com/package/next-seo#json-ld
        */}
        {/* {seo?.schema?.raw ? (
          <script
            type="application/ld+json"
            className="yoast-schema-graph"
            key="yoastSchema"
            dangerouslySetInnerHTML={{ __html: sanitize(seo?.schema?.raw) }}
          />
        ) : null} */}

        {/*
          See https://cookie-script.com/
          IMPORTANT: this script must be inserted in the header as the first script before all other scripts.
        */}
        {process.env.NEXT_PUBLIC_COOKIE_SCRIPT_BANNER_ID &&
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script type="text/javascript" charSet="UTF-8" src={ `//cdn.cookie-script.com/s/${process.env.NEXT_PUBLIC_COOKIE_SCRIPT_BANNER_ID}.js`}></script>
        }
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Header pageType={pageType} languages={languages} translations={translations} mainMenus={mainMenus?.edges} serviceMenus={serviceMenus?.edges} />
        <StyledBox component="main">
          {children}
        </StyledBox>
        <Footer footerMenus={footerMenus?.edges} socialLinks={socialLinks?.edges} />

      </ThemeProvider>
    </Box>
  );
};

export default SiteLayout;
