import React, { useState, useRef, useImperativeHandle } from 'react'
import { styled } from '@mui/material/styles'
import Menu, { MenuProps } from '@mui/material/Menu'
import Box from '@mui/material/Box'
import { SecondLevelMenuItem } from './SecondLevelMenuItem'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import OmniLink from '@/components/ui/OmniLink'
import { ArrowSquareIcon } from '@/components/icons'

const StyledWrapperBox = styled(Box)({
  '& + .MuiBox-root': {
    borderTop: 'solid 1px #979797',
  },
});

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)({
  pointerEvents: 'none',
  '.MuiList-root': {
    padding: 0,
    margin: '1px',
    background: '#fff',
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: '#000000',
    // maxWidth: 'calc(100vw - 370px)',
    width: '730px',
  },
});

const StyledOuterBox = styled(Box)({
  padding: '30px 48px 10px',
  pointerEvents: 'auto',
});

const StyledBoxMultiCol = styled(Box)({
  pointerEvents: 'auto',
  columnGap: '20px',
  paddingBottom: '20px',
  '&::after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
});

export const NestedMenuItem = React.forwardRef<any, any>(function NestedMenuItem(props, ref) {
	const {
    parentMenuOpen,
    href,
		label,
		rightIcon = <ArrowSquareIcon />,
		leftIcon = null,
		children,
		tabIndex: tabIndexProp,
    viewAllLabel,
    viewAllUrl,
		ContainerProps: ContainerPropsProp = {},
		...MenuItemProps
	} = props;

	const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp

	const menuItemRef = useRef<any>(null)
	useImperativeHandle(ref, () => menuItemRef.current)

	const containerRef = useRef<any>(null)
	useImperativeHandle(containerRefProp, () => containerRef.current)

	const menuContainerRef = useRef<any>(null)

	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false)

	const handleMouseEnter = (event: any) => {
		setIsSubMenuOpen(true)

		if (ContainerProps.onMouseEnter) {
			ContainerProps.onMouseEnter(event)
		}
	}
	const handleMouseLeave = (event: any) => {
		setIsSubMenuOpen(false)

		if (ContainerProps.onMouseLeave) {
			ContainerProps.onMouseLeave(event)
		}
	}

	// Check if any immediate children are active
	const isSubmenuFocused = () => {
		const active = containerRef?.current?.ownerDocument?.activeElement;
    if (menuContainerRef?.current?.children) {
      for (const child of menuContainerRef?.current?.children) {
        if (child === active) {
          return true
        }
      }
    }
		return false
	}

	const handleFocus = (event: any) => {
		if (event.target === containerRef.current) {
			setIsSubMenuOpen(true)
		}

		if (ContainerProps.onFocus) {
			ContainerProps.onFocus(event)
		}
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Escape') {
			return
		}

		if (isSubmenuFocused()) {
			event.stopPropagation()
		}

		const active = containerRef.current.ownerDocument.activeElement

		if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
			containerRef.current.focus()
		}

		if (
			event.key === 'ArrowRight' &&
			event.target === containerRef.current &&
			event.target === active
		) {
			const firstChild = menuContainerRef.current.children[0]
			firstChild.focus()
		}
	}

	const open = isSubMenuOpen && parentMenuOpen

	// Root element must have a `tabIndex` attribute for keyboard navigation
	let tabIndex
	if (!props.disabled) {
		tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
	}

	return (
		<StyledWrapperBox
			{...ContainerProps}
			ref={containerRef}
			onFocus={handleFocus}
			tabIndex={tabIndex}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onKeyDown={handleKeyDown}
		>
			{SecondLevelMenuItem({
				MenuItemProps,
				ref: menuItemRef,
        href,
				leftIcon,
				rightIcon,
				label,
        active: open,
			})}

			<StyledMenu
				// Set pointer events to 'none' to prevent the invisible Popover div
				// from capturing events for clicks and hovers
				anchorEl={menuItemRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={open}
				autoFocus={false}
				disableAutoFocus
				disableEnforceFocus
				onClose={() => {
					setIsSubMenuOpen(false)
				}}
			>
				<StyledOuterBox ref={menuContainerRef}>
          <Stack>
            <StyledBoxMultiCol sx={{
              columns: (children.length <= 8 ? '190px 2' : '190px 3')
            }}>
					    {children}
            </StyledBoxMultiCol>
            {viewAllUrl && <Box sx={{ display: 'flex', justifyItems: 'flex-end', padding: '10px', borderTop: 'solid 1px #979797' }}>
              <Button component={OmniLink} href={viewAllUrl} sx={{ marginLeft: 'auto' }}>
                {viewAllLabel}
              </Button>
            </Box>}
          </Stack>
				</StyledOuterBox>
			</StyledMenu>
		</StyledWrapperBox>
	)
})
